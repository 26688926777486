@import url("https://fonts.googleapis.com/css2?family=Meow+Script&family=Montserrat:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Meow+Script&family=Montserrat:wght@300;400;500;600;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: Montserrat;
  box-sizing: border-box;
  color: #2A2A2A;
}

html, body {
  height: -moz-fit-content;
  height: fit-content;
  width: 100vw;
}

body {
  background-color: #F2F2F2;
  scroll-behavior: smooth;
}

.App {
  position: relative;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.moveMenu {
  transform: translateY(0px) !important;
  transition: transform cubic-bezier(0.73, 0, 0, 1.03) 0.3s !important;
}

.moveHeader {
  transform: translateX(-160px) !important;
  transition: transform ease 0.3s;
}

.bg-element {
  position: absolute;
  transform: scale(2);
  background-color: #F2F2F2;
  box-shadow: 0px 0px 9px rgba(141, 141, 141, 0.22);
  height: 130px;
  width: 130px;
  z-index: -1;
}

.circular-element {
  border-radius: 100%;
}

.top {
  top: 283px;
  right: -70px;
}

.triangle-element {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-right: 50px solid #F2F2F2;
  border-bottom: 25px solid transparent;
  top: 157px;
  left: -33px;
  transform: rotate(45deg) scale(2.5);
}

.bottom {
  top: 70%;
  left: -119px;
}

.show_element {
  opacity: 1 !important;
  transition: opacity ease 0.7s !important;
}

.show_element--delay_1 {
  opacity: 1 !important;
  transition: opacity 0.4s ease 10s !important;
}

.show_element--delay_2 {
  opacity: 1 !important;
  transition: opacity 0.4s ease !important;
  transition-delay: 10s !important;
}

#Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #2A2A2A;
}

#Home section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  scroll-snap-type: y mandatory;
  scroll-snap-align: start;
}

#Landing #HeadLine, #Landing #Job_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#HeadLine p {
  font-size: 1rem;
  margin: 0;
  color: #2A2A2A;
  font-weight: 500;
}

#Job_title .Job_text {
  font-size: 3rem;
  margin: 0;
  font-weight: 900;
  line-height: 40px;
  color: #2A2A2A;
}

.Section_title {
  position: relative;
}
.Section_title p {
  font-size: 2.3rem;
  font-weight: 500;
}
.Section_title .underline {
  position: absolute;
  width: 100%;
  height: 15px;
  top: 30px;
  z-index: -1;
  background: linear-gradient(to right, #ffbb6e, #FF79C1);
}

#About {
  position: relative;
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: 150vh !important;
}
#About #Background_Pattern {
  top: 200px;
  text-transform: uppercase;
  font-size: 6rem;
  font-weight: 800;
  color: #e6e6e6;
  position: absolute;
}
#About .About_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.4s ease;
  gap: 40px;
  padding: 0px 20px;
}
#About #Skills_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
#About #Skills_list .skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}
#About #Skills_list .skills p {
  font-weight: 700;
}
#About #Skills_list .skills .skills_card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
#About #Skills_list .cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 32px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 0px 9px;
  border-radius: 15px;
  border: 2px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(to right, #FF6D90 -58%, #F9E795 164%);
}
#About #Skills_list .cards p {
  color: #2A2A2A;
  font-weight: 500;
}
#About #About_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-items: flex-start;
}
#About #About_title p {
  font-size: 2.2rem;
  text-align: left;
  font-weight: 700;
}
#About #About_title #Paragraphs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}
#About #About_title #Paragraphs p {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 22px;
}

#Contact {
  gap: 20px;
  align-items: flex-start;
}
#Contact .Connections {
  width: 90%;
  gap: 20px;
}
#Contact .Connections, #Contact .connection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#Contact .underline {
  width: 0 !important;
  transition: width 0.5s ease 0.1s;
}
#Contact .expand_underline {
  width: 100% !important;
  transition: width 0.5s ease 0.1s;
}
#Contact p {
  opacity: 0;
}
#Contact .Connections a {
  text-decoration: none;
  font-size: 3rem;
  font-weight: 700;
  color: #d1d1d1;
  transition: text-shadow ease-out 1s, color ease-out 0.4s !important;
  cursor: pointer;
  opacity: 0;
}
#Contact .Connections a:hover {
  color: #ffbb6e;
  text-shadow: #ffbb6e 0px 4px 12px;
  transition: text-shadow ease-out 1s, color ease-out 0.4s !important;
}

.Move_Home {
  transform: translateX(-137px);
}

.open_menu {
  transform: translateX(-156px) !important;
}

#Links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 100vh;
  width: 48vw;
  background-color: #00002B;
  position: absolute;
  top: 0;
  right: 0;
}

.Links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  list-style: none;
  padding: 0;
}
.Links li {
  margin-bottom: 10px;
}
.Links a {
  text-decoration: none;
  color: #2A2A2A;
  font-weight: 500;
  font-size: 1.5rem;
  transition: color ease 0.4s;
}
.Links a:hover {
  color: white;
  transition: color ease 0.4s;
}
.Links .active {
  color: #F2F2F2;
  font-weight: 600;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 16vh;
  position: fixed;
  top: 0;
  z-index: 200;
  transform: translateX(0px);
  transition: transform ease 0.3s;
  padding: 0 10%;
}
header #burger {
  background: transparent;
  border: none;
  cursor: pointer;
}
header #burger .burger, header #burger .burger::after, header #burger .burger::before {
  background-color: #2A2A2A;
}
header #burger .burger.invert, header #burger .burger.invert::after, header #burger .burger.invert::before {
  background-color: #F2F2F2;
}
header #logo {
  width: 50px;
  height: auto;
  content: url("../assets/logo_mobile.svg");
}
header nav {
  display: none;
}

#socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: 10px;
  position: fixed;
  top: 90%;
  left: 25vw;
}
#socials #line {
  width: 100px;
  height: 1px;
  background-color: #d1d1d1;
}
#socials a {
  font-size: 2rem;
}

#TechStack {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
  gap: 5px;
}
#TechStack li::marker {
  color: #FF79C1;
}
#TechStack .note {
  color: #FF79C1;
  font-size: 10px;
}

#Menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  background-color: #ffbb6e;
  width: 100vw;
  height: 100vh;
  right: 0;
  transform: translateY(-300vw);
  transition: transform cubic-bezier(0.73, 0, 0, 1.03) 0.3s !important;
}

@media (min-width: 375px) {
  #Job_title .Job_text {
    font-size: 4rem;
    line-height: 53px;
  }
  #HeadLine p {
    font-size: 1.2rem;
  }
  #About {
    gap: 20px;
  }
  #About .Paragraphs {
    line-height: 22px;
  }
}
@media (min-width: 768px) {
  header #logo {
    width: 154px;
    content: url("../assets/logo_desktop.svg");
  }
  #About {
    height: 100vh !important;
  }
  #About #About_title {
    gap: 18px;
  }
  #About #About_title p {
    font-size: 3.5rem;
  }
  #About #About_title #Paragraphs {
    gap: 15px;
  }
  #About #About_title #Paragraphs p {
    font-size: 1.2rem;
    line-height: 33px;
  }
  #About #Background_Pattern {
    top: 120px;
    left: -110px;
    font-size: 14rem;
  }
  #About .About_wrapper {
    gap: 40px;
    padding: 0px 70px;
  }
  #About #Skills_list .skills > p {
    font-size: 1.5rem;
  }
  #About #Skills_list .skills_card {
    gap: 13px;
    width: 100%;
  }
  #About #Skills_list .cards {
    width: 109px;
    height: 42px;
  }
  .bottom {
    top: 78%;
  }
  .top {
    top: 621px;
    right: -71px;
  }
  #Menu {
    width: 100vw;
  }
  #socials {
    top: 85vh;
    left: 40vw;
    order: 1;
  }
  #socials svg {
    height: 1.7rem;
  }
  #socials #line {
    height: 1px;
    width: 100px;
  }
  #HeadLine {
    order: 2;
  }
  #HeadLine > p {
    font-size: 1.5rem;
    position: relative;
    left: 5px;
  }
  #Landing {
    flex-direction: row !important;
    gap: 60px;
  }
  #Job_title .Job_text {
    font-size: 6rem;
    line-height: 84px;
  }
  #About .Paragraphs {
    width: 80%;
    font-size: 1.2rem;
    line-height: 32px;
    max-width: 700px;
  }
  #Contact .Connections {
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .triangle-element {
    top: 560px !important;
    left: 157px !important;
  }
  header .Links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 19px;
  }
  header .Links a {
    font-size: 1.2rem;
    color: #2A2A2A;
  }
  #Job_title .Job_text {
    font-size: 8rem;
    line-height: 104px;
  }
  #Landing #socials #line {
    height: 193px;
  }
  #Landing #socials svg {
    height: 1.5rem;
  }
}
@media (min-width: 1280px) {
  #socials {
    flex-direction: column;
    top: 45vh;
    left: 10vw;
  }
  #socials #line {
    height: 100px;
    width: 1px;
  }
  #About #About_title p {
    font-size: 3rem;
  }
  #About #About_title p #Paragraphs p {
    font-size: 1rem;
    line-height: 33px;
  }
  #About .About_wrapper {
    width: 70%;
    max-width: 1023px;
  }
  #About #Skills_list .skills .skills_card {
    width: 100%;
  }
  .Section_title p {
    font-size: 3rem;
  }
  .Section_title .underline {
    top: 40px;
  }
  .bg-element {
    transform: scale(3.5) rotate(45deg);
  }
  .bottom {
    left: -69px;
  }
  .top {
    top: 789px;
    right: -67px;
  }
}
@media (min-width: 1500px) {
  #HeadLine > p {
    font-size: 2rem;
  }
  #About {
    position: relative;
  }
  #About .About_wrapper {
    width: 70%;
    height: 100%;
    gap: 40px;
  }
  #About #Background_list {
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
  }
  #About #Background_list .locations p:nth-child(1), #About #Background_list .locations p:nth-child(3) {
    font-size: 1rem;
  }
  #About #Background_list .locations p *:nth-child(2) {
    font-size: 1.4rem;
  }
  #About #Background_list .details_header p {
    font-size: 2rem;
  }
  #About #Background_list .details_header svg {
    font-size: 1.7rem;
  }
}
@media (min-width: 2000px) {
  #About #About_title p {
    font-size: 5rem;
  }
  #About #Paragraphs p {
    line-height: 40px;
  }
}
* {
  padding: 0;
  margin: 0;
  font-family: Montserrat;
  box-sizing: border-box;
  color: #2A2A2A;
}

html, body {
  height: -moz-fit-content;
  height: fit-content;
  width: 100vw;
}

body {
  background-color: #F2F2F2;
  scroll-behavior: smooth;
}

.App {
  position: relative;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.moveMenu {
  transform: translateY(0px) !important;
  transition: transform cubic-bezier(0.73, 0, 0, 1.03) 0.3s !important;
}

.moveHeader {
  transform: translateX(-160px) !important;
  transition: transform ease 0.3s;
}

.bg-element {
  position: absolute;
  transform: scale(2);
  background-color: #F2F2F2;
  box-shadow: 0px 0px 9px rgba(141, 141, 141, 0.22);
  height: 130px;
  width: 130px;
  z-index: -1;
}

.circular-element {
  border-radius: 100%;
}

.top {
  top: 283px;
  right: -70px;
}

.triangle-element {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-right: 50px solid #F2F2F2;
  border-bottom: 25px solid transparent;
  top: 157px;
  left: -33px;
  transform: rotate(45deg) scale(2.5);
}

.bottom {
  top: 70%;
  left: -119px;
}

.show_element {
  opacity: 1 !important;
  transition: opacity ease 0.7s !important;
}

.show_element--delay_1 {
  opacity: 1 !important;
  transition: opacity 0.4s ease 10s !important;
}

.show_element--delay_2 {
  opacity: 1 !important;
  transition: opacity 0.4s ease !important;
  transition-delay: 10s !important;
}

#Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #2A2A2A;
}

#Home section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  scroll-snap-type: y mandatory;
  scroll-snap-align: start;
}

#Landing #HeadLine, #Landing #Job_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#HeadLine p {
  font-size: 1rem;
  margin: 0;
  color: #2A2A2A;
  font-weight: 500;
}

#Job_title .Job_text {
  font-size: 3rem;
  margin: 0;
  font-weight: 900;
  line-height: 40px;
  color: #2A2A2A;
}

.Section_title {
  position: relative;
}
.Section_title p {
  font-size: 2.3rem;
  font-weight: 500;
}
.Section_title .underline {
  position: absolute;
  width: 100%;
  height: 15px;
  top: 30px;
  z-index: -1;
  background: linear-gradient(to right, #ffbb6e, #FF79C1);
}

#About {
  position: relative;
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: 150vh !important;
}
#About #Background_Pattern {
  top: 200px;
  text-transform: uppercase;
  font-size: 6rem;
  font-weight: 800;
  color: #e6e6e6;
  position: absolute;
}
#About .About_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.4s ease;
  gap: 40px;
  padding: 0px 20px;
}
#About #Skills_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
#About #Skills_list .skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}
#About #Skills_list .skills p {
  font-weight: 700;
}
#About #Skills_list .skills .skills_card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
#About #Skills_list .cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 32px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 0px 9px;
  border-radius: 15px;
  border: 2px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(to right, #FF6D90 -58%, #F9E795 164%);
}
#About #Skills_list .cards p {
  color: #2A2A2A;
  font-weight: 500;
}
#About #About_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-items: flex-start;
}
#About #About_title p {
  font-size: 2.2rem;
  text-align: left;
  font-weight: 700;
}
#About #About_title #Paragraphs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}
#About #About_title #Paragraphs p {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 22px;
}

#Contact {
  gap: 20px;
  align-items: flex-start;
}
#Contact .Connections {
  width: 90%;
  gap: 20px;
}
#Contact .Connections, #Contact .connection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#Contact .underline {
  width: 0 !important;
  transition: width 0.5s ease 0.1s;
}
#Contact .expand_underline {
  width: 100% !important;
  transition: width 0.5s ease 0.1s;
}
#Contact p {
  opacity: 0;
}
#Contact .Connections a {
  text-decoration: none;
  font-size: 3rem;
  font-weight: 700;
  color: #d1d1d1;
  transition: text-shadow ease-out 1s, color ease-out 0.4s !important;
  cursor: pointer;
  opacity: 0;
}
#Contact .Connections a:hover {
  color: #ffbb6e;
  text-shadow: #ffbb6e 0px 4px 12px;
  transition: text-shadow ease-out 1s, color ease-out 0.4s !important;
}

.Move_Home {
  transform: translateX(-137px);
}

.open_menu {
  transform: translateX(-156px) !important;
}

#Links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 100vh;
  width: 48vw;
  background-color: #00002B;
  position: absolute;
  top: 0;
  right: 0;
}

.Links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  list-style: none;
  padding: 0;
}
.Links li {
  margin-bottom: 10px;
}
.Links a {
  text-decoration: none;
  color: #2A2A2A;
  font-weight: 500;
  font-size: 1.5rem;
  transition: color ease 0.4s;
}
.Links a:hover {
  color: white;
  transition: color ease 0.4s;
}
.Links .active {
  color: #F2F2F2;
  font-weight: 600;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 16vh;
  position: fixed;
  top: 0;
  z-index: 200;
  transform: translateX(0px);
  transition: transform ease 0.3s;
  padding: 0 10%;
}
header #burger {
  background: transparent;
  border: none;
  cursor: pointer;
}
header #burger .burger, header #burger .burger::after, header #burger .burger::before {
  background-color: #2A2A2A;
}
header #burger .burger.invert, header #burger .burger.invert::after, header #burger .burger.invert::before {
  background-color: #F2F2F2;
}
header #logo {
  width: 50px;
  height: auto;
  content: url("../assets/logo_mobile.svg");
}
header nav {
  display: none;
}

#socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: 10px;
  position: fixed;
  top: 90%;
  left: 25vw;
}
#socials #line {
  width: 100px;
  height: 1px;
  background-color: #d1d1d1;
}
#socials a {
  font-size: 2rem;
}

#TechStack {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
  gap: 5px;
}
#TechStack li::marker {
  color: #FF79C1;
}
#TechStack .note {
  color: #FF79C1;
  font-size: 10px;
}

#Menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  background-color: #ffbb6e;
  width: 100vw;
  height: 100vh;
  right: 0;
  transform: translateY(-300vw);
  transition: transform cubic-bezier(0.73, 0, 0, 1.03) 0.3s !important;
}

@media (min-width: 375px) {
  #Job_title .Job_text {
    font-size: 4rem;
    line-height: 53px;
  }
  #HeadLine p {
    font-size: 1.2rem;
  }
  #About {
    gap: 20px;
  }
  #About .Paragraphs {
    line-height: 22px;
  }
}
@media (min-width: 768px) {
  header #logo {
    width: 154px;
    content: url("../assets/logo_desktop.svg");
  }
  #About {
    height: 100vh !important;
  }
  #About #About_title {
    gap: 18px;
  }
  #About #About_title p {
    font-size: 3.5rem;
  }
  #About #About_title #Paragraphs {
    gap: 15px;
  }
  #About #About_title #Paragraphs p {
    font-size: 1.2rem;
    line-height: 33px;
  }
  #About #Background_Pattern {
    top: 120px;
    left: -110px;
    font-size: 14rem;
  }
  #About .About_wrapper {
    gap: 40px;
    padding: 0px 70px;
  }
  #About #Skills_list .skills > p {
    font-size: 1.5rem;
  }
  #About #Skills_list .skills_card {
    gap: 13px;
    width: 100%;
  }
  #About #Skills_list .cards {
    width: 109px;
    height: 42px;
  }
  .bottom {
    top: 78%;
  }
  .top {
    top: 621px;
    right: -71px;
  }
  #Menu {
    width: 100vw;
  }
  #socials {
    top: 85vh;
    left: 40vw;
    order: 1;
  }
  #socials svg {
    height: 1.7rem;
  }
  #socials #line {
    height: 1px;
    width: 100px;
  }
  #HeadLine {
    order: 2;
  }
  #HeadLine > p {
    font-size: 1.5rem;
    position: relative;
    left: 5px;
  }
  #Landing {
    flex-direction: row !important;
    gap: 60px;
  }
  #Job_title .Job_text {
    font-size: 6rem;
    line-height: 84px;
  }
  #About .Paragraphs {
    width: 80%;
    font-size: 1.2rem;
    line-height: 32px;
    max-width: 700px;
  }
  #Contact .Connections {
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .triangle-element {
    top: 560px !important;
    left: 157px !important;
  }
  header .Links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 19px;
  }
  header .Links a {
    font-size: 1.2rem;
    color: #2A2A2A;
  }
  #Job_title .Job_text {
    font-size: 8rem;
    line-height: 104px;
  }
  #Landing #socials #line {
    height: 193px;
  }
  #Landing #socials svg {
    height: 1.5rem;
  }
}
@media (min-width: 1280px) {
  #socials {
    flex-direction: column;
    top: 45vh;
    left: 10vw;
  }
  #socials #line {
    height: 100px;
    width: 1px;
  }
  #About #About_title p {
    font-size: 3rem;
  }
  #About #About_title p #Paragraphs p {
    font-size: 1rem;
    line-height: 33px;
  }
  #About .About_wrapper {
    width: 70%;
    max-width: 1023px;
  }
  #About #Skills_list .skills .skills_card {
    width: 100%;
  }
  .Section_title p {
    font-size: 3rem;
  }
  .Section_title .underline {
    top: 40px;
  }
  .bg-element {
    transform: scale(3.5) rotate(45deg);
  }
  .bottom {
    left: -69px;
  }
  .top {
    top: 789px;
    right: -67px;
  }
}
@media (min-width: 1500px) {
  #HeadLine > p {
    font-size: 2rem;
  }
  #About {
    position: relative;
  }
  #About .About_wrapper {
    width: 70%;
    height: 100%;
    gap: 40px;
  }
  #About #Background_list {
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
  }
  #About #Background_list .locations p:nth-child(1), #About #Background_list .locations p:nth-child(3) {
    font-size: 1rem;
  }
  #About #Background_list .locations p *:nth-child(2) {
    font-size: 1.4rem;
  }
  #About #Background_list .details_header p {
    font-size: 2rem;
  }
  #About #Background_list .details_header svg {
    font-size: 1.7rem;
  }
}
@media (min-width: 2000px) {
  #About #About_title p {
    font-size: 5rem;
  }
  #About #Paragraphs p {
    line-height: 40px;
  }
}
.shadow-element {
  position: absolute;
  background-color: #ebebeb;
  width: 200px;
  height: 200px;
  z-index: -1;
}

.sq-shadow {
  top: 640px;
  right: 150px;
  animation-name: rotate;
  animation-duration: 200s;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.circle-shadow {
  border-radius: 100%;
  transform: scale(6.5);
}

#Work {
  position: relative;
  padding-bottom: 80px;
}

#Work_landing, #Works, #Work_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.show_project {
  opacity: 1;
  transition: opacity ease 0.3s;
}

#Work_landing h1 {
  font-size: 5rem;
}
#Work_landing p {
  font-weight: 500;
}

#Works {
  height: -moz-fit-content;
  height: fit-content;
  gap: 45px;
  padding-bottom: 150px;
}
#Works #Work_wrap {
  gap: 40px;
}
#Works .Project {
  cursor: pointer;
  width: 80%;
}
#Works .Project img {
  width: 100%;
}
#Works .Project .Content {
  text-align: left;
  font-size: 0.9rem;
  word-break: break-all;
}
#Works .Project .Content h3, #Works .Project .Content p {
  color: #2A2A2A !important;
}
#Works .Project .image_wrap .Content {
  display: none;
}

#under_construction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
#under_construction .wrapper {
  height: 50%;
}

@media (min-width: 768px) {
  #Work_landing h1 {
    font-size: 8rem;
  }
  #Works #Work_wrap {
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    justify-content: flex-start;
    gap: 40px;
    height: -moz-fit-content;
    height: fit-content;
  }
  #Works .Project {
    width: 46%;
  }
}
@media (min-width: 1280px) {
  #Works .Project .Content {
    display: none;
    font-size: 2rem;
  }
  #Works .Project .image_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
  }
  #Works .Project .image_wrap .Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex !important;
    position: absolute;
    top: 50%;
    z-index: 2;
    opacity: 0;
    transition: opacity ease 0.3s;
  }
  #Works .Project .image_wrap .Content h3 {
    color: white !important;
  }
  #Works .Project .image_wrap .Content p {
    color: white !important;
    font-size: 1rem;
    max-width: 80%;
    word-break: normal;
  }
  #Works .Project .image_wrap::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(12, 12, 12, 0.77);
    opacity: 0;
    transition: opacity ease 0.3s;
  }
  #Works .Project .image_wrap:hover .Content {
    opacity: 1;
    transition: opacity ease 0.3s;
  }
  #Works .Project .image_wrap:hover::after {
    opacity: 1;
    transition: opacity ease 0.3s;
  }
}/*# sourceMappingURL=work.css.map */